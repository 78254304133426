export const contentTableConfig = {
  propList: [
    { prop: 'payTime', label: '打赏时间', slotName: 'payTime' },
    {
      prop: 'shopName',
      label: '打赏门店',
      slotName: 'shopName'
    },
    { prop: 'orderNo', label: '订单编号', slotName: 'orderNo' },
    { prop: 'appTime', label: '预约时间' },
    {
      prop: 'userName',
      label: '用户信息',
      slotName: 'userName'
    },
    {
      prop: 'ruleName',
      label: '打赏类型',
      slotName: 'ruleName'
    },
    
    { prop: 'price', label: '打赏金额', slotName: 'price' },
    { prop: 'empName', label: '打赏员工', slotName: 'empName' },
    { prop: 'remark', label: '用户留言', slotName: 'remark' }
    // { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}

export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '打赏明细',
    path: ''
  }
]
