<template>
  <div class="reward">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="reward-content">
      <topContent @handleSearchClick="handleSearchClick" @queryInfoChange="handleQueryInfoChange"></topContent>
      <page-content @selectionChange="selectionChange" :dataList="dataList" :dataCount="dataCount"
        :contentTableConfig="contentTableConfig" :isButtonStyle="true" @tableCellClick="handleTableCellClick">
        <template #pagination>
          <el-pagination v-model:currentPage="queryInfo.current" v-model:page-size="queryInfo.size"
            :page-sizes="[10, 20, 30, 40]"  layout="total, sizes, prev, pager, next, jumper"
            :total="dataCount" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </template>
        <template #price="scope">
          <div class="price">
            ¥ {{ scope.row.price }}
          </div>
        </template>
      </page-content>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import { getOrderRewardList } from '@/service/main/staff'
import { COMPANY_ID } from '@/common/constant'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    Breadcrumb,
    topContent
  },
  setup() {

    const ruleForm = reactive({
      start: 1,
      size: 10,
      empId: 0
    })

    const handleSizeChange = pagesize => {
     queryInfo.size = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.current = page
      initPage()
    }

    const queryInfo = reactive({
      current: 1,
      size: 10,
      empId: '',
      shopId: '',
      startDate: '',
      endDate: ''
    })

    const handleQueryInfoChange = (obj) => {
      queryInfo.empId = obj.empId,
        queryInfo.shopId = obj.shopId
      queryInfo.startDate = obj.startDate
      queryInfo.endDate = obj.endDate
    }

    const handleSearchClick=()=>{
        initPage()
    }

    const dataList = ref([])
    const dataCount = ref(0)
    const initPage = async () => {
      const res = await getOrderRewardList({ ...queryInfo })
      dataList.value = res.data.list
      dataCount.value = Number(res.data.total)
    }
    initPage()




    return {
      handleSearchClick,
      handleQueryInfoChange,
      queryInfo,
      ruleForm,
      dataList,
      dataCount,
      contentTableConfig,
      handleSizeChange,
      handleCurrentChange,
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.reward {
  .reward-content {
    margin: 20px;
    padding: 20px;
    background-color: #fff;

    .price {
      color: #ff6600;
    }

    .userRealname {
      display: flex;
      align-items: center;

      .avatar {
        display: flex;
        align-items: center;
      }

      img {
        width: 35px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}
</style>




