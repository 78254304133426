<template>
    <div class="top-content">
        <el-date-picker @change="handleDateChange"
            v-model="queryInfo.date"
            type="daterange"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间" />
        <el-select clearable
            @change="handleShopChange"
            v-model="queryInfo.shopId"
            class="m-2"
            placeholder="选择门店">
            <el-option v-for="item in shopList"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId">
            </el-option>
        </el-select>
        <el-select clearable
            class="teacher"
            v-model="queryInfo.empId"
            placeholder="选择理疗师"
            filterable
            size="large">
            <el-option v-for="item in shopTeacherList"
                :key="item.userId"
                :label="item.userRealname"
                :value="item.userId" />
        </el-select>
        <el-button @click="handleSearchClick"
            type="primary">搜索</el-button>

    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { getShopList,getShopTeacherList } from '@/service/main/order'
// import { getShopTeacherList } from '@/service/main/reserve'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import { emit } from 'process'

export default defineComponent({
  props: {

  },
  components: {

  },
  emits: ['queryInfoChange', 'handleSearchClick'],
  setup(props, { emit }) {

    const shopList = ref([])
    const shopTeacherList = ref([])

    const handleShopChange = (value) => {
      queryInfo.empId = ''
      if (String(value).length == 0) return
      getTeacherFunc(value)
    }

    const handleDateChange = (value) => {

      if(value==null){
        queryInfo.startDate =''
        queryInfo.endDate = ''
        return
      }
      let startDate = dayjs(value[0]).format('YYYY-MM-DD')
      let endDate = dayjs(value[1]).format('YYYY-MM-DD')
      queryInfo.startDate = startDate
      queryInfo.endDate = endDate

    }

    const handleSearchClick = () => {
      emit('handleSearchClick')
    }

    const queryInfo = reactive({
      page: 1,
      pagesize: 10,
      empId: '',
      shopId: '',
      startDate: '',
      endDate: '',
      date: ''
    })

    const getTeacherFunc = async (shopId) => {
      const res = await getShopTeacherList(shopId)
      shopTeacherList.value = res.data.list
    }

    watch(queryInfo, (n) => {
      emit('queryInfoChange', { ...n })
    }, {
      deep: true
    })

    const initPage = async () => {
      const shopRes = await getShopList(COMPANY_ID)
      shopList.value = shopRes.data.list
      if (shopList.value.length > 0) {
        getTeacherFunc(shopList.value[0].shopId)
      }
    }
    initPage()

    return {
      handleSearchClick,
      handleDateChange,
      getTeacherFunc,
      shopTeacherList,
      shopList,
      handleShopChange,
      queryInfo,

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  &:deep(.el-select) {
    margin-left: 10px;
  }

  &:deep(.el-button) {
    margin-left: 10px;
  }
}
</style>




